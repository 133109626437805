// Generated by Framer (b5638f1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import * as sharedStyle from "../css/AveuZunRs";

const enabledGestures = {LI5I7kLeA: {hover: true}};

const cycleOrder = ["LI5I7kLeA", "bo_o9NHn3"];

const serializationHash = "framer-OMI5u"

const variantClassNames = {bo_o9NHn3: "framer-v-ii7lez", LI5I7kLeA: "framer-v-1msrowh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Active: "LI5I7kLeA", Disactive: "bo_o9NHn3"}

const getProps = ({click, height, id, title, width, ...props}) => { return {...props, hjXjefcyy: click ?? props.hjXjefcyy, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "LI5I7kLeA", xDogNU_YZ: title ?? props.xDogNU_YZ ?? "Recht "} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, xDogNU_YZ, hjXjefcyy, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "LI5I7kLeA", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapghwx4o = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (hjXjefcyy) {const res = await hjXjefcyy(...args);
if (res === false) return false;}
setVariant("bo_o9NHn3")
})

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1msrowh", className, classNames)} data-framer-name={"Active"} data-highlight layoutDependency={layoutDependency} layoutId={"LI5I7kLeA"} onTap={onTapghwx4o} ref={refBinding} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} variants={{"LI5I7kLeA-hover": {backgroundColor: "var(--token-bdd5c3bb-385d-4b4d-b487-5a1f9f5d346c, rgb(250, 250, 250))"}, bo_o9NHn3: {backgroundColor: "rgba(230, 230, 232, 0)"}}} {...addPropertyOverrides({"LI5I7kLeA-hover": {"data-framer-name": undefined}, bo_o9NHn3: {"data-framer-name": "Disactive"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1trpgeu"} data-styles-preset={"AveuZunRs"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-aede051f-1300-4483-bef4-86e2d220cba0, rgb(60, 80, 116)))"}}>Recht </motion.p></React.Fragment>} className={"framer-1m6195s"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"Sd_TUe1bY"} style={{"--extracted-r6o4lv": "var(--token-aede051f-1300-4483-bef4-86e2d220cba0, rgb(60, 80, 116))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={xDogNU_YZ} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OMI5u.framer-80xdlu, .framer-OMI5u .framer-80xdlu { display: block; }", ".framer-OMI5u.framer-1msrowh { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 16px; position: relative; width: 288px; }", ".framer-OMI5u .framer-1m6195s { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-OMI5u.framer-1msrowh { gap: 0px; } .framer-OMI5u.framer-1msrowh > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-OMI5u.framer-1msrowh > :first-child { margin-left: 0px; } .framer-OMI5u.framer-1msrowh > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 61
 * @framerIntrinsicWidth 288
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"bo_o9NHn3":{"layout":["fixed","auto"]},"kyOgZHE6O":{"layout":["fixed","auto"]}}}
 * @framerVariables {"xDogNU_YZ":"title","hjXjefcyy":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerlWncBxmqn: React.ComponentType<Props> = withCSS(Component, css, "framer-OMI5u") as typeof Component;
export default FramerlWncBxmqn;

FramerlWncBxmqn.displayName = "Tag";

FramerlWncBxmqn.defaultProps = {height: 61, width: 288};

addPropertyControls(FramerlWncBxmqn, {variant: {options: ["LI5I7kLeA", "bo_o9NHn3"], optionTitles: ["Active", "Disactive"], title: "Variant", type: ControlType.Enum}, xDogNU_YZ: {defaultValue: "Recht ", displayTextArea: false, title: "Title", type: ControlType.String}, hjXjefcyy: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerlWncBxmqn, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})